<template>
  <div class="navigator-car-wrap">
    <container>
      <div class="navigator-car">
        <BtnMenu />
        <div class="navigator-switches">
          <div class="navigator-switches__item active">Навигатор</div>
          <div
            class="navigator-switches__item"
            @click="$router.push('/where-charge')"
          >
            Где заряжаться
          </div>
        </div>
        <div class="navigator-drop show">
          <div class="navigator-inputs">
            <AppInput
              class="navigator-car__input"
              idInput="navigator-car__input1"
              error="Заполните поле"
              placeholder="Откуда"
              search-data=""
              @appInput="inputAppFunc"
            />
            <AppInput
              class="navigator-car__input"
              idInput="navigator-car__input2"
              error="Заполните поле"
              placeholder="Куда"
              search-data=""
              @appInput="inputAppFunc2"
            />
          </div>

          <div class="navigator-temperature temperature-wrap settings-item">
            <h3>Текущий процент заряда</h3>

            <div class="temperature-slider-wrap">
              <div id="temperature-slider">
                <div class="temperature-points-wrap">
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                </div>
              </div>

              <div id="slider-start" class="slider-start">0</div>

              <div id="slider-end" class="slider-end">100</div>
            </div>
          </div>

          <div class="map-legend">
            <div class="map-legend__item">
              <div class="map-legend__item-svg">
                <svg-icon height="32" name="map-legend-icon1" width="27" />
              </div>
              <div class="map-legend__item-text"
                >Быстрые зарядные станции, в том числе в ДЦ Porsche</div
              >
            </div>
            <div class="map-legend__item">
              <div class="map-legend__item-svg">
                <svg-icon height="32" name="map-legend-icon2" width="27" />
              </div>
              <div class="map-legend__item-text">Зарядные станции в городе</div>
            </div>
          </div>
        </div>
        <div class="navigator-drop-arrow" @click="navigatorDrop">
          <svg-icon height="36" name="charging-arrow-up" width="80" />
        </div>
        <div class="error-marchrut" v-show="errorMarh">
          <span>Заряда аккумулятора не хватит чтобы доехать</span>
        </div>
      </div>
    </container>
    <div class="navigator-map">
      <yandex-map
        :settings="settings"
        :coords="center"
        :zoom="zoomMap"
        :controls="['zoomControl']"
        :options="{ suppressMapOpenBlock: true, minZoom: 3, maxZoom: 18 }"
        :use-object-manager="true"
        @boundschange="chargeMap"
        @map-was-initialized="initMap"
      >
      </yandex-map>
      <div class="navigator-map__block-inf">
        <div
          v-if="!networkStatus"
          class="error-inf d-flex justify-center align-center"
        >
          <div class="navigator-map__error-text">
            <p>Проверьте подключение к интернету</p>
          </div>
        </div>
        <div
          v-else-if="error"
          class="error-inf d-flex justify-center align-center"
        >
          <div class="navigator-map__error-text">
            <p>
              Выберите пункт отправления и назначения, чтобы узнать время в пути
              с учетом зарядки
            </p>
          </div>
        </div>
        <div v-else class="travelTime d-flex">
          <div class="travelTime__result">
            <p class="travelTime__title">
              {{
                info
                  ? "Примерное время в пути"
                  : "Время в пути с учётом зарядки"
              }}
            </p>
            <span>{{ prettyTime(resultTime) }}</span>
            <div class="time-text-navigator d-flex justify-between">
              <div class="time-text__item">часы</div>
              <div class="time-text__item">минуты</div>
            </div>
          </div>
          <div class="travelTime__line"></div>

          <div
            :class="{ 'travelTime__count-margin': !countCharging }"
            class="travelTime__count"
          >
            <p class="travelTime__title">Сколько раз заряжаться</p>
            <h3 v-show="!countCharging" class="travelTime__count-info">
              Время и количество зарядных сессий зависит от мощности зарядных
              станций. Уточните их характеристики перед поездкой.
            </h3>
            <span v-show="countCharging">{{ countCharging }}</span>
          </div>
          <div v-if="countCharging" class="travelTime__time">
            <p class="travelTime__title">Время зарядки</p>
            <span>{{ prettyTime(chargingTime) }}</span>
            <div class="time-text-navigator-time d-flex justify-between">
              <div class="time-text__item">часы</div>
              <div class="time-text__item">минуты</div>
            </div>
          </div>
        </div>
        <div class="navigator-map__description d-flex">
          <svg-icon name="alert-circle-white" />
          <p>
            Приведённая информация носит ознакомительный характер, не является
            исчерпывающей. При планировании поездки, пожалуйста, уточняйте
            точные координаты работающих зарядок, способы их оплаты и подходящие
            к ним аксессуары.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from "../components/Container/Container.vue";
import BtnMenu from "../components/BtnMenu.vue";
import AppInput from "../components/AppInput.vue";
import SvgIcon from "../components/IconBase";
import noUiSlider from "nouislider";
import $ from "jquery";
import { VueOfflineMixin } from "vue-offline";
import { toTime } from "@/functions/toTime";
import { yandexMap } from "vue-yandex-maps";
import plagArray from "../plug.json";

const maxProb = 340;
const maxPontChage = 3;

export default {
  components: {
    Container,
    BtnMenu,
    AppInput,
    SvgIcon,
    yandexMap,
  },
  mixins: [VueOfflineMixin],
  data: () => {
    return {
      switchPlag: true,
      error: true,
      info: false,
      resultTime: 0,
      countCharging: 0,
      chargingTime: 0,
      zoomMap: 10,
      prob: null,
      settings: {
        apiKey: "330cf713-ad09-45f2-ba8e-1572de7b7131",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      inputApp: "",
      inputApp2: "",
      errorMarh: false,
      plugArr: [],
      mapMarh: null,
      center: [55.755819, 37.617655],
      conterPlaceNum: 0,
      metrM: null,
      vremSec: null,
      pointChargeNum1: null,
      pointChargeNum2: null,
      pointChargeNum3: null,
      idmaxPoit: 600,
      actPoint: [],
      objectManager: null,
      thatmap: null,
      timefull: {
        3.6: 25,
        7.2: 12.5,
        11: 8.2,
        20: 4.5,
        22: 4.1,
        24: 3.75,
        40: 2.25,
        43: 2.09,
        45: 2,
        50: 1.8,
        150: 1.3,
        270: 1.1,
      },
    };
  },
  mounted() {
    this.plugArr = plagArray;
    let temp_slider = document.getElementById("temperature-slider");

    noUiSlider.create(temp_slider, {
      start: [52],
      connect: true,
      range: {
        min: [0],
        max: [100],
      },
    });

    $("#temperature-slider .noUi-handle").append(
      '<div class="current-temp">20</div>'
    );
    const sliderEd = $(".slider-end");
    const sliderStart = $(".slider-start");
    const currentTemp = $("#temperature-slider .current-temp");
    temp_slider.noUiSlider.on("update", (values, handle) => {
      let temp = Math.round(values[handle]);
      this.prob = Math.round((maxProb / 100) * temp * 1000);
      currentTemp.html(temp);
      if (temp > 85) {
        sliderEd.addClass("hidden-1");
        if (temp > 95) {
          sliderEd.addClass("hidden-2");
        } else {
          sliderEd.removeClass("hidden-2");
        }
      } else {
        sliderEd.removeClass("hidden-1");
        sliderEd.removeClass("hidden-2");
      }
      if (temp < 12) {
        sliderStart.addClass("hidden");
      } else {
        sliderStart.removeClass("hidden");
      }
    });
  },
  methods: {
    prettyTime(val) {
      return toTime(val);
    },
    navigatorDrop() {
      let drop = document.querySelector(".navigator-drop");
      drop.classList.toggle("show");
    },
    PlugShareSwitch(isSwitch) {
      this.switchPlag = isSwitch;
    },
    chargeMap({ originalEvent }) {
      var result = ymaps
        .geoQuery(originalEvent.map.geoObjects)
        .searchIntersect(originalEvent.map);
      if (result._objects) {
        this.conterPlaceNum = result._objects.length;
      }
    },
    inputAppFunc(val) {
      this.inputApp = val;
    },
    inputAppFunc2(val) {
      this.inputApp2 = val;
    },
    distFrom(lat1, lng1, lat2, lng2) {
      let earthRadius = 6371000; //meters
      let dLat = (lat2 - lat1) * (Math.PI / 180);
      let dLng = (lng2 - lng1) * (Math.PI / 180);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let dist = Math.round(earthRadius * c);

      return dist;
    },
    initMap(mapinfo) {
      //this.center = [55.755819, 37.617644];
      this.thatmap = mapinfo;
      let suggestView1 = new ymaps.SuggestView("navigator-car__input1", {
        results: 4,
      });
      let suggestView2 = new ymaps.SuggestView("navigator-car__input2", {
        results: 4,
      });
      this.objectManager = new ymaps.ObjectManager();
      mapinfo.geoObjects.add(this.objectManager);
      this.objectManager.add(plagArray);
      //
      /*  let multiRoute = new ymaps.multiRouter.MultiRoute({
        referencePoints: [
          "Россия, Удмуртская Республика, Ижевск, улица имени Короткова, 3",
          "Россия, Удмуртская Республика, Ижевск, улица Холмогорова, 37А ",
        ],
      });
      if (this.mapMarh) {
        mapinfo.geoObjects.remove(this.mapMarh);
      }
      this.mapMarh = multiRoute;
      let coordStartTest = null;
      multiRoute.model.events.add("requestsuccess", () => {
        let activeRoute = multiRoute.getActiveRoute();
        if (activeRoute) {
          this.metrM = Math.round(activeRoute.properties.get("distance").value);
          this.vremSec = Math.round(
            activeRoute.properties.get("duration").value
          );
          this.resultTime = this.vremSec;
          let wayPoints = multiRoute.getWayPoints();
          coordStartTest = [];
          let coordFinish = [];
          wayPoints.each((point, i) => {
            if (i == 0) {
              coordStartTest = point.geometry.getCoordinates();
            }
            coordFinish = point.geometry.getCoordinates();
          });
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          this.chargingTime = 0;
          this.countCharging = 0;
          if (this.actPoint.length) {
            this.objectManager.remove(this.actPoint);
            this.actPoint.forEach((elem) => {
              elem.options.iconImageHref = "img/pinBlue.svg";
              this.idmaxPoit = this.idmaxPoit + 1;
              elem.id = this.idmaxPoit;
            });
            this.objectManager.add(this.actPoint);
            this.actPoint = [];
          }
          if (this.metrM > this.prob) {
            marhrvia(coordStartTest, coordFinish, this.prob);
          } else {
            this.error = false;
            mapinfo.geoObjects.add(multiRoute);
          }
        }
      }); */

      //
      let temp_slider = document.getElementById("temperature-slider");

      temp_slider.noUiSlider.on("change", (values, handle) => {
        this.errorMarh = false;
        if (this.inputApp && this.inputApp2) {
          let multiRoute = new ymaps.multiRouter.MultiRoute(
            {
              referencePoints: [this.inputApp, this.inputApp2],
            },
            colorMarhrut
          );
          if (this.mapMarh) {
            mapinfo.geoObjects.remove(this.mapMarh);
          }
          this.mapMarh = multiRoute;
          multiRoute.model.events.add("requestsuccess", () => {
            let activeRoute = multiRoute.getActiveRoute();
            if (activeRoute) {
              this.metrM = Math.round(
                activeRoute.properties.get("distance").value
              );
              this.vremSec = Math.round(
                activeRoute.properties.get("duration").value
              );
              this.resultTime = this.vremSec;
              let wayPoints = multiRoute.getWayPoints();
              coordStart = [];
              let coordFinish = [];
              wayPoints.each((point, i) => {
                if (i == 0) {
                  coordStart = point.geometry.getCoordinates();
                }
                coordFinish = point.geometry.getCoordinates();
              });
              this.pointChargeNum1 = null;
              this.pointChargeNum2 = null;
              this.pointChargeNum3 = null;
              this.chargingTime = 0;
              this.countCharging = 0;
              if (this.actPoint.length) {
                this.objectManager.remove(this.actPoint);
              }
              setTimeout(() => {
                if (this.actPoint.length) {
                  this.actPoint.forEach((elem) => {
                    if (elem.dealership) {
                      elem.options.iconImageHref = "img/pinBlue.svg";
                    } else {
                      elem.options.iconImageHref = "img/pinGreen.svg";
                    }
                    let date = new Date();
                    this.idmaxPoit = String(date.getTime());
                    elem.id = this.idmaxPoit;
                  });
                  this.objectManager.add(this.actPoint);
                  this.actPoint = [];
                }
                setTimeout(() => {
                  if (this.metrM > this.prob) {
                    marhrvia(coordStart, coordFinish, this.prob);
                  } else {
                    mapinfo.geoObjects.add(multiRoute);
                  }
                }, 200);
              }, 400);
            }
          });
        }
      });
      //
      const marhrvia = (startcoord, finishcoord, testprob) => {
        if (!testprob) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          return;
        }
        let circle = new ymaps.Circle([startcoord, testprob], null);
        let objectsInsideCircle = ymaps
          .geoQuery(this.objectManager.objects)
          .searchIntersect(circle);
        function compare(a, b) {
          if (Number(Object.keys(a)[0]) < Number(Object.keys(b)[0])) {
            return -1;
          }
          if (Number(Object.keys(a)[0]) > Number(Object.keys(b)[0])) {
            return 1;
          }
          return 0;
        }
        let massRaston = [];
        objectsInsideCircle.each((point, i) => {
          let coordPoint = point.geometry.getCoordinates();
          let rastonFinish = this.distFrom(
            finishcoord[0],
            finishcoord[1],
            coordPoint[0],
            coordPoint[1]
          );
          let summ = rastonFinish;
          massRaston.push({ [summ]: coordPoint });
        });
        if (!massRaston.length) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          return;
        }
        massRaston.sort(compare);
        console.log("Зарядились 1 раз");
        this.countCharging = 1;
        let prob2 = [Object.keys(massRaston[0])[0]];
        let pointCharge = massRaston[0][Object.keys(massRaston[0])[0]];
        let rastonStart = this.distFrom(
          startcoord[0],
          startcoord[1],
          pointCharge[0],
          pointCharge[1]
        );
        rastonStart = rastonStart + rastonStart * 0.2;
        let idPoint = null;
        for (let index = 0; index < plagArray.features.length; index++) {
          const element = plagArray.features[index];
          if (element.geometry.coordinates[0] == pointCharge[0]) {
            if (element.geometry.coordinates[1] == pointCharge[1]) {
              idPoint = element;
              if (element.connectors) {
                this.pointChargeNum1 = element.connectors;
              }
              break;
            }
          }
        }
        if (idPoint) {
          this.objectManager.remove(idPoint);
        }
        if (idPoint) {
          let clone = {};
          for (let key in idPoint) {
            clone[key] = idPoint[key];
          }
          if (clone.dealership) {
            clone.options.iconImageHref = "img/pinBlueOp.svg";
          } else {
            clone.options.iconImageHref = "img/pinGreenOp.svg";
          }
          let date = new Date();
          this.idmaxPoit = String(date.getTime());
          clone.id = this.idmaxPoit;
          this.actPoint.push(clone);
          this.objectManager.add(this.actPoint);
        }
        //this.objectManager.add(plagArray);
        let razn = Math.round(testprob - rastonStart);
        if (razn < 0) {
          razn = 0;
        }
        let procentCharge = Math.round(
          ((maxProb * 1000 - razn) / (maxProb * 1000)) * 100
        );
        procentCharge = procentCharge / 100;
        if (this.pointChargeNum1) {
          this.chargingTime =
            this.timefull[this.pointChargeNum1] * 60 * 60 * procentCharge;
        } else {
          this.pointChargeNum1 == 22;
          this.chargingTime = 4.1 * 60 * 60 * procentCharge;
        }
        this.resultTime = this.resultTime + this.chargingTime;
        if (prob2 > 340000) {
          marhrvia2(
            massRaston[0][Object.keys(massRaston[0])[0]],
            finishcoord,
            maxProb * 1000
          );
        } else {
          this.error = false;
          let multiRoute = new ymaps.multiRouter.MultiRoute(
            {
              referencePoints: [
                startcoord,
                massRaston[0][Object.keys(massRaston[0])[0]],
                finishcoord,
              ],
              params: {
                viaIndexes: [1],
              },
            },
            colorMarhrut
          );
          if (this.mapMarh) {
            mapinfo.geoObjects.remove(this.mapMarh);
          }
          this.mapMarh = multiRoute;
          mapinfo.geoObjects.add(multiRoute);
        }
      };

      const marhrvia2 = (startcoord, finishcoord, testprob) => {
        if (!testprob) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          return;
        }
        let circle = new ymaps.Circle([startcoord, testprob], null);
        let objectsInsideCircle = ymaps
          .geoQuery(this.objectManager.objects)
          .searchIntersect(circle);
        function compare(a, b) {
          if (Number(Object.keys(a)[0]) < Number(Object.keys(b)[0])) {
            return -1;
          }
          if (Number(Object.keys(a)[0]) > Number(Object.keys(b)[0])) {
            return 1;
          }
          return 0;
        }
        let massRaston = [];
        objectsInsideCircle.each((point, i) => {
          let coordPoint = point.geometry.getCoordinates();
          let rastonFinish = this.distFrom(
            finishcoord[0],
            finishcoord[1],
            coordPoint[0],
            coordPoint[1]
          );
          let summ = rastonFinish;
          massRaston.push({ [summ]: coordPoint });
        });
        if (!massRaston.length) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          return;
        }
        massRaston.sort(compare);
        let prob2 = [Object.keys(massRaston[0])[0]];
        console.log("Зарядились 2ой раз");
        this.countCharging = 2;
        let idPoint = null;
        let pointCharge = massRaston[0][Object.keys(massRaston[0])[0]];
        let rastonStart = this.distFrom(
          startcoord[0],
          startcoord[1],
          pointCharge[0],
          pointCharge[1]
        );
        rastonStart = rastonStart + rastonStart * 0.2;
        for (let index = 0; index < plagArray.features.length; index++) {
          const element = plagArray.features[index];
          if (element.geometry.coordinates[0] == pointCharge[0]) {
            if (element.geometry.coordinates[1] == pointCharge[1]) {
              idPoint = element;
              if (element.connectors) {
                this.pointChargeNum2 = element.connectors;
              }
              break;
            }
          }
        }
        /* if (idPoint && this.objectManager) {
          this.objectManager.remove(idPoint);
          let clone = {};
          for (let key in idPoint) {
            clone[key] = idPoint[key];
          }
          if (clone.dealership) {
            clone.options.iconImageHref = "img/pinBlueOp.svg";
          } else {
            clone.options.iconImageHref = "img/pinGreenOp.svg";
          }
          let date = new Date();
          this.idmaxPoit = String(date.getTime());
          clone.id = this.idmaxPoit;
          this.actPoint.push(clone);
          if (this.objectManager && clone) {
            this.objectManager.add(clone);
          }
        } */
        if (idPoint) {
          this.objectManager.remove(idPoint);
        }
        if (idPoint) {
          let clone = {};
          for (let key in idPoint) {
            clone[key] = idPoint[key];
          }
          if (clone.dealership) {
            clone.options.iconImageHref = "img/pinBlueOp.svg";
          } else {
            clone.options.iconImageHref = "img/pinGreenOp.svg";
          }
          let date = new Date();
          this.idmaxPoit = String(
            date.getTime() * Math.floor(Math.random() * 1000)
          );
          clone.id = this.idmaxPoit;
          this.actPoint.push(clone);
          this.objectManager.add(clone);
        }
        let razn = Math.round(testprob - rastonStart);
        if (razn < 0) {
          razn = 0;
        }
        let procentCharge = Math.round(
          ((maxProb * 1000 - razn) / (maxProb * 1000)) * 100
        );
        procentCharge = procentCharge / 100;
        if (this.pointChargeNum2) {
          this.chargingTime =
            this.chargingTime +
            this.timefull[this.pointChargeNum2] * 60 * 60 * procentCharge;
        } else {
          this.pointChargeNum2 == 22;
          this.chargingTime = this.chargingTime + 4.1 * 60 * 60 * procentCharge;
        }
        this.resultTime = this.resultTime + this.chargingTime;
        if (prob2 > 340000) {
          marhrvia3(
            massRaston[0][Object.keys(massRaston[0])[0]],
            finishcoord,
            maxProb * 1000,
            startcoord
          );
        } else {
          this.error = false;
          let multiRoute = new ymaps.multiRouter.MultiRoute(
            {
              referencePoints: [
                coordStart,
                startcoord,
                massRaston[0][Object.keys(massRaston[0])[0]],
                finishcoord,
              ],
              params: {
                viaIndexes: [1, 2],
              },
            },
            colorMarhrut
          );
          if (this.mapMarh) {
            mapinfo.geoObjects.remove(this.mapMarh);
          }
          this.mapMarh = multiRoute;
          mapinfo.geoObjects.add(multiRoute);
        }
      };

      const marhrvia3 = (startcoord, finishcoord, testprob, startcoord2) => {
        if (!testprob) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          return;
        }
        let circle = new ymaps.Circle([startcoord, testprob], null);
        let objectsInsideCircle = ymaps
          .geoQuery(this.objectManager.objects)
          .searchIntersect(circle);
        function compare(a, b) {
          if (Number(Object.keys(a)[0]) < Number(Object.keys(b)[0])) {
            return -1;
          }
          if (Number(Object.keys(a)[0]) > Number(Object.keys(b)[0])) {
            return 1;
          }
          return 0;
        }
        let massRaston = [];
        objectsInsideCircle.each((point, i) => {
          let coordPoint = point.geometry.getCoordinates();
          let rastonFinish = this.distFrom(
            finishcoord[0],
            finishcoord[1],
            coordPoint[0],
            coordPoint[1]
          );
          let summ = rastonFinish;
          massRaston.push({ [summ]: coordPoint });
        });
        if (!massRaston.length) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          return;
        }
        massRaston.sort(compare);

        let prob2 = [Object.keys(massRaston[0])[0]];
        console.log("Зарядились 3й раз");
        this.countCharging = 3;
        let idPoint = null;
        let pointCharge = massRaston[0][Object.keys(massRaston[0])[0]];
        let rastonStart = this.distFrom(
          startcoord[0],
          startcoord[1],
          pointCharge[0],
          pointCharge[1]
        );
        rastonStart = rastonStart + rastonStart * 0.2;
        for (let index = 0; index < plagArray.features.length; index++) {
          const element = plagArray.features[index];
          if (element.geometry.coordinates[0] == pointCharge[0]) {
            if (element.geometry.coordinates[1] == pointCharge[1]) {
              idPoint = element;
              if (element.connectors) {
                this.pointChargeNum3 = element.connectors;
              }
              break;
            }
          }
        }
        if (idPoint) {
          this.objectManager.remove(idPoint);
        }
        if (idPoint) {
          let clone = {};
          for (let key in idPoint) {
            clone[key] = idPoint[key];
          }
          if (clone.dealership) {
            clone.options.iconImageHref = "img/pinBlueOp.svg";
          } else {
            clone.options.iconImageHref = "img/pinGreenOp.svg";
          }
          let date = new Date();
          this.idmaxPoit = String(
            date.getTime() * Math.floor(Math.random() * 1000)
          );
          clone.id = this.idmaxPoit;
          this.actPoint.push(clone);
          this.objectManager.add(clone);
        }
        let razn = Math.round(testprob - rastonStart);
        if (razn < 0) {
          razn = 0;
        }
        let procentCharge = Math.round(
          ((maxProb * 1000 - razn) / (maxProb * 1000)) * 100
        );
        procentCharge = procentCharge / 100;
        if (this.pointChargeNum3) {
          this.chargingTime =
            this.chargingTime +
            this.timefull[this.pointChargeNum3] * 60 * 60 * procentCharge;
        } else {
          this.pointChargeNum3 == 22;
          this.chargingTime = this.chargingTime + 4.1 * 60 * 60 * procentCharge;
        }
        this.resultTime = this.resultTime + this.chargingTime;
        if (prob2 > 340000) {
          this.errorMarh = true;
          this.resultTime = 0;
          this.countCharging = 0;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
        } else {
          this.error = false;
          let multiRoute = new ymaps.multiRouter.MultiRoute(
            {
              referencePoints: [
                coordStart,
                startcoord2,
                startcoord,
                massRaston[0][Object.keys(massRaston[0])[0]],
                finishcoord,
              ],
              params: {
                viaIndexes: [1, 2, 3],
              },
            },
            colorMarhrut
          );
          if (this.mapMarh) {
            mapinfo.geoObjects.remove(this.mapMarh);
          }
          this.mapMarh = multiRoute;
          mapinfo.geoObjects.add(multiRoute);
        }
      };
      let colorMarhrut = {
        boundsAutoApply: true,
        /* routeStrokeColor: "#000088", */
        routeActiveStrokeWidth: 3,
        /* routeActiveStrokeColor: "#60D2BA", */
        /* routeActiveStrokeColor: "#0ADBB5", */
        routeActiveStrokeColor: "#00BFA5",
        routeStrokeWidth: 3,
        wayPointFinishIconLayout: "default#image",
        wayPointFinishIconImageHref: "img/finishMap.svg",
        wayPointFinishIconImageSize: [40, 48],
        wayPointFinishIconImageOffset: [-20, -48],
        wayPointStartIconLayout: "default#image",
        wayPointStartIconImageHref: "img/startMap.svg",
        wayPointStartIconImageSize: [40, 48],
        wayPointStartIconImageOffset: [-20, -48],
        boundsAutoApply: true,
      };
      //
      let coordStart = null;
      suggestView1.events.add("select", (e) => {
        let res = e.get("item").value;
        let myGeocoder = ymaps.geocode(res);
        this.inputApp = res;
        this.errorMarh = false;
        this.error = false;
        myGeocoder.then((res) => {
          document.activeElement.blur();
          if (this.inputApp && this.inputApp2) {
            let multiRoute = new ymaps.multiRouter.MultiRoute(
              {
                referencePoints: [this.inputApp, this.inputApp2],
              },
              colorMarhrut
            );
            if (this.mapMarh) {
              mapinfo.geoObjects.remove(this.mapMarh);
            }
            this.mapMarh = multiRoute;
            multiRoute.model.events.add("requestsuccess", () => {
              let activeRoute = multiRoute.getActiveRoute();
              if (activeRoute) {
                this.metrM = Math.round(
                  activeRoute.properties.get("distance").value
                );
                this.vremSec = Math.round(
                  activeRoute.properties.get("duration").value
                );
                this.resultTime = this.vremSec;
                let wayPoints = multiRoute.getWayPoints();
                coordStart = [];
                let coordFinish = [];
                wayPoints.each((point, i) => {
                  if (i == 0) {
                    coordStart = point.geometry.getCoordinates();
                  }
                  coordFinish = point.geometry.getCoordinates();
                });
                this.pointChargeNum1 = null;
                this.pointChargeNum2 = null;
                this.pointChargeNum3 = null;
                this.chargingTime = 0;
                this.countCharging = 0;
                if (this.actPoint.length) {
                  this.objectManager.remove(this.actPoint);
                }
                if (this.actPoint.length) {
                  setTimeout(() => {
                    this.actPoint.forEach((elem) => {
                      if (elem.dealership) {
                        elem.options.iconImageHref = "img/pinBlue.svg";
                      } else {
                        elem.options.iconImageHref = "img/pinGreen.svg";
                      }
                      this.idmaxPoit = this.idmaxPoit + 1;
                      elem.id = this.idmaxPoit;
                    });
                    this.objectManager.add(this.actPoint);
                    this.actPoint = [];
                  }, 100);
                }
                if (this.metrM > this.prob) {
                  marhrvia(coordStart, coordFinish, this.prob);
                } else {
                  mapinfo.geoObjects.add(multiRoute);
                }
              }
            });
          }
        });
      });
      suggestView2.events.add("select", (e) => {
        let res = e.get("item").value;
        let myGeocoder = ymaps.geocode(res);
        this.inputApp2 = res;
        this.errorMarh = false;
        this.error = false;
        myGeocoder.then((res) => {
          document.activeElement.blur();
          if (this.inputApp && this.inputApp2) {
            let multiRoute = new ymaps.multiRouter.MultiRoute(
              {
                referencePoints: [this.inputApp, this.inputApp2],
              },
              colorMarhrut
            );
            if (this.mapMarh) {
              mapinfo.geoObjects.remove(this.mapMarh);
            }
            this.mapMarh = multiRoute;
            multiRoute.model.events.add("requestsuccess", () => {
              let activeRoute = multiRoute.getActiveRoute();
              if (activeRoute) {
                this.metrM = Math.round(
                  activeRoute.properties.get("distance").value
                );
                this.vremSec = Math.round(
                  activeRoute.properties.get("duration").value
                );
                this.resultTime = this.vremSec;
                let wayPoints = multiRoute.getWayPoints();
                coordStart = [];
                let coordFinish = [];
                wayPoints.each((point, i) => {
                  if (i == 0) {
                    coordStart = point.geometry.getCoordinates();
                  }
                  coordFinish = point.geometry.getCoordinates();
                });
                this.pointChargeNum1 = null;
                this.pointChargeNum2 = null;
                this.pointChargeNum3 = null;
                this.chargingTime = 0;
                this.countCharging = 0;
                if (this.actPoint.length) {
                  this.objectManager.remove(this.actPoint);
                }
                if (this.actPoint.length) {
                  setTimeout(() => {
                    this.actPoint.forEach((elem) => {
                      if (elem.dealership) {
                        elem.options.iconImageHref = "img/pinBlue.svg";
                      } else {
                        elem.options.iconImageHref = "img/pinGreen.svg";
                      }
                      this.idmaxPoit = this.idmaxPoit + 1;
                      elem.id = this.idmaxPoit;
                    });
                    this.objectManager.add(this.actPoint);
                    this.actPoint = [];
                  }, 100);
                }
                if (this.metrM > this.prob) {
                  marhrvia(coordStart, coordFinish, this.prob);
                } else {
                  mapinfo.geoObjects.add(multiRoute);
                }
              }
            });
          }
        });
      });
    },
  },
  watch: {
    isOnline() {},
    inputApp(val) {
      if (val == "" && this.mapMarh) {
        if (this.mapMarh && this.thatmap) {
          this.thatmap.geoObjects.remove(this.mapMarh);
          this.error = true;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          this.chargingTime = 0;
          this.countCharging = 0;
          if (this.actPoint.length) {
            this.objectManager.remove(this.actPoint);
          }
          if (this.actPoint.length) {
            setTimeout(() => {
              this.actPoint.forEach((elem) => {
                if (elem.dealership) {
                  elem.options.iconImageHref = "img/pinBlue.svg";
                } else {
                  elem.options.iconImageHref = "img/pinGreen.svg";
                }
                this.idmaxPoit = this.idmaxPoit + 1;
                elem.id = this.idmaxPoit;
              });
              this.objectManager.add(this.actPoint);
              this.actPoint = [];
            }, 10);
          }
        }
      }
    },
    inputApp2(val) {
      if (val == "" && this.mapMarh) {
        if (this.mapMarh && this.thatmap) {
          this.thatmap.geoObjects.remove(this.mapMarh);
          this.error = true;
          this.pointChargeNum1 = null;
          this.pointChargeNum2 = null;
          this.pointChargeNum3 = null;
          this.chargingTime = 0;
          this.countCharging = 0;
          if (this.actPoint.length) {
            this.objectManager.remove(this.actPoint);
          }
          if (this.actPoint.length) {
            setTimeout(() => {
              this.actPoint.forEach((elem) => {
                if (elem.dealership) {
                  elem.options.iconImageHref = "img/pinBlue.svg";
                } else {
                  elem.options.iconImageHref = "img/pinGreen.svg";
                }
                this.idmaxPoit = this.idmaxPoit + 1;
                elem.id = this.idmaxPoit;
              });
              this.objectManager.add(this.actPoint);
              this.actPoint = [];
            }, 10);
          }
        }
      }
    },
    /* metrM(val) {
      if (val > this.prob) {
        this.errorMarh = true;
      } else if (this.errorMarh) {
        this.errorMarh = false;
      }
    },
    prob(val) {
      if (this.metrM > val) {
        this.errorMarh = true;
      } else if (this.errorMarh) {
        this.errorMarh = false;
      }
    }, */
  },
  computed: {
    networkStatus() {
      return !!this.isOnline;
    },
  },
};
</script>

<style lang="scss">
.customBalloon {
  max-width: 224px;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  font-family: "PorscheNext";
  padding: 20px 40px 20px 20px;
  background: #60d2ba;
}

.customBalloon.blue {
  background: #3ec5fc;
}

.customBalloon__arrow {
  bottom: 20px;
  width: 2px;
  height: 9px;
  margin-left: 55px;
  background: #60d2ba;
}

.customBalloon__arrow.blue {
  background: #3ec5fc;
}

.customBalloon__line {
  background: white;
  width: 100%;
  height: 2px;
  margin: 10px 0;
}
.error-marchrut {
  position: absolute;
  bottom: -37px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rebeccapurple;
  width: 100vw;
  height: 37px;
  font-size: 16px;
}
.customBalloon__description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__close-button {
  background: url(../assets/icon/x.png) center no-repeat;
  height: 35px;
  width: 40px;
  opacity: 1;
}
/* .ymaps-2-1-79-routerRoutes-pane {
  opacity: 1 !important;
} */
.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__layout {
  background: transparent;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__content {
  background: transparent;
}

.ymaps-2-1-79-balloon-overlay .ymaps-2-1-79-balloon {
  box-shadow: none;
}

.ymaps-2-1-79-balloon
  .ymaps-2-1-79-balloon__close
  + .ymaps-2-1-79-balloon__content {
  margin: 0;
  padding: 0;
}

.ymaps-2-1-79-balloon-overlay
  .ymaps-2-1-79-balloon_to_top
  .ymaps-2-1-79-balloon__tail {
  transform: none;
  box-shadow: none;
  background: transparent;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__tail:after {
  display: none;
}

html,
body,
#app,
.navigator-car-wrap {
  height: 100%;
}

.navigator-car-wrap {
  display: flex;
  flex-direction: column;
}

.navigator-car {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 100px;
}

.navigator-map {
  display: flex;
  flex: 1;
  background-color: #ffffff;
  position: relative;
}

.navigator-map__block-inf {
  position: absolute;
  bottom: 48px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #000000;
  max-width: 800px;
  padding: 32px 26px;
  height: 220px;
}

.navigator-map__description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  margin-top: 12px;

  svg {
    width: 32px;
    margin-right: -1px;
    margin-top: -2px;
  }
}

.error-inf {
  height: 110px;
}

.navigator-map__error-text {
  max-width: 482px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
}

.travelTime span {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  margin-left: 10px;
}

.travelTime__title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  margin-left: 10px;
}

.travelTime__line {
  border: 2px solid #3ec5fc;
  max-height: 106px;
  margin: 0 36px;
}

.travelTime__count {
  margin-left: -6px;
}

.travelTime .travelTime__count-margin {
  margin: 0 auto;
}

.travelTime__time {
  max-width: 150px;
  margin-left: 35px;

  span {
    margin-left: 5px;
  }
}

.travelTime__count-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  max-width: 311px;
  margin: 17px auto auto;
}

.navigator-switches {
  display: flex;
}

.navigator-switches__item {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navigator-switches__item.active {
  border-bottom: 2px solid #3ec5fc;
  padding-bottom: 4px;
}

.navigator-switches__item + .navigator-switches__item {
  margin-left: 40px;
}

.navigator-inputs {
  display: flex;
  margin-top: 39px;
}

.navigator-car__input {
  width: calc(50% - 12px);
  display: flex;
}

.navigator-car__input + .navigator-car__input {
  margin-left: 22px;
}

.navigator-car__input .input {
  width: 100%;
}
.navigator-inputs {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.navigator-drop {
  position: relative;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s, visibility 0.3s, opacity 0.3s;
}

.navigator-drop.show {
  opacity: 1;
  visibility: visible;
  height: auto;
  max-height: 350px;
}

.navigator-car .map-legend {
  display: flex;
  justify-content: center;
  margin-top: 37px;
  width: 100%;
}

.map-legend__item {
  display: flex;
  align-items: center;
  max-width: 350px;
  width: max-content;
}

.map-legend__item + .map-legend__item {
  margin-left: 32px;
}

.map-legend__item-svg {
}

.map-legend__item-svg svg {
  display: block;
}

.map-legend__item-text {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 20px;
  width: 290px;
  min-width: 290px;
}

.navigator-drop-arrow {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
}

.navigator-drop-arrow svg {
  display: block;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navigator-drop.show + .navigator-drop-arrow svg {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.navigator-temperature {
  width: 418px;
  margin: auto;
  margin-top: 31px;
}

.navigator-temperature .temperature-slider-wrap {
  width: 100%;
}

.navigator-temperature .noUi-origin {
  padding-left: 16px;
}

.navigator-temperature .current-temp {
  text-align: center;
}

.slider-end {
  transition: opacity 0.3s;
}

.slider-start {
  transition: opacity 0.3s;
}

.hidden {
  opacity: 0.1;
}

.hidden-1 {
  opacity: 0.2;
}

.hidden-2 {
  opacity: 0;
}

.temperature-points-wrap {
  position: absolute;
  width: 100%;
  height: 8px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 3px;
}

.temperature-points__item {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  top: 2px;
}

.noUi-base,
.noUi-connects {
  z-index: auto;
}

.navigator-temperature h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}

.ymap-container {
  width: 100%;
  height: 100%;
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(100%);
}

.ymaps-2-1-79-map {
  width: 100% !important;
  height: 100% !important;
}

.navigator-car__input .ymaps-2-1-79-search__suggest {
  background-color: #262626;
  border: none;
  margin-top: 5px;
}
.navigator-car__input .ymaps-2-1-79-search__suggest:before {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  width: 32px;
  background: linear-gradient(270deg, #262626 0%, rgba(38, 38, 38, 0) 100%);
}
.navigator-car__input .ymaps-2-1-79-search__suggest-item {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 0;
  text-overflow: inherit;
  padding: 16px 10px 16px 24px;
}

.navigator-car__input .ymaps-2-1-79-suggest-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navigator-car__input .ymaps-2-1-79-search__suggest {
  left: 0;
  right: 0;
}
.navigator-car__input .ymaps-2-1-79-search__suggest-item_selected_yes {
  background: none;
}
.navigator-car__input .ymaps-2-1-79-suggest-item-0 {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .map-legend__item {
    max-width: 255px !important;
  }
  .navigator-car__input .ymaps-2-1-79-search__suggest-item {
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    padding: 12px 10px 12px 18px;
  }
  .navigator-car__input .ymaps-2-1-79-suggest-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navigator-car__input .ymaps-2-1-79-search__suggest:before {
    width: 26px;
  }

  .navigator-car__input .ymaps-2-1-79-search__suggest {
    left: 0;
    right: 0;
  }
  .navigator-car__input .ymaps-2-1-79-search__suggest-item_selected_yes {
    background: none;
  }
  .navigator-car__input .ymaps-2-1-79-suggest-item-0 {
    margin-top: 0;
  }
  .map-legend__item-text {
    font-size: 21px;
    width: 218px;
    min-width: 218px;
  }

  .navigator-switches__item {
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }

  .navigator-switches__item.active {
    padding-bottom: 3px;
  }

  .navigator-switches__item + .navigator-switches__item {
    margin-left: 32px;
  }

  .navigator-inputs {
    margin-top: 30px;
  }

  .navigator-car__input {
    width: calc(50% - 9px);
  }

  .navigator-car__input + .navigator-car__input {
    margin-left: 18px;
  }

  .navigator-temperature h3 {
    font-size: 16px;
    line-height: 100%;
  }

  .navigator-temperature {
    width: 330px;
    margin-top: 26px;
  }

  .navigator-temperature .temperature-points-wrap {
    height: 3px;
  }

  .navigator-temperature #temperature-slider {
    height: 6px;
  }

  .temperature-points__item {
    width: 4px;
    height: 4px;
    top: 1px;
  }

  .navigator-temperature #temperature-slider .noUi-handle {
    transform: scale(0.8);
  }

  .navigator-temperature #temperature-slider .current-temp {
    transform: scale(1.2);
    margin-top: 15px;
  }

  .navigator-temperature .noUi-origin {
    padding-left: 10px;
  }

  #temperature-slider .noUi-connects {
    background: linear-gradient(270deg, #60d2ba 56.07%, #d5001c 86.87%);
  }

  .map-legend__item-text {
    font-size: 18px;
    line-height: 150%;
    margin-left: 16px;
  }

  .map-legend__item-svg {
    height: 25px;
    min-width: 21px;
  }

  .map-legend__item-svg svg {
    height: 100%;
    width: 100%;
  }

  .map-legend__item + .map-legend__item {
    margin-left: 25px;
  }

  .navigator-car .map-legend {
    margin-top: 22px;
  }

  .navigator-drop-arrow {
    margin-top: 20px;
  }

  .navigator-drop-arrow svg {
    display: block;
    width: 64px;
    height: 28px;
  }

  .navigator-map__block-inf {
    max-width: 634px;
    height: 174px;
    padding: 22px 24px;
  }

  .travelTime__title {
    font-size: 16px;
    margin-left: 4px;
  }

  .travelTime span {
    font-size: 48px;
    margin-left: 0;
  }

  .navigator-map__description {
    font-size: 11px;
    margin-top: 9px;
  }

  .navigator-map__description svg {
    width: 21px;
    margin-right: 4px;
    margin-top: -4px;
  }

  .travelTime__line {
    border: 2px solid #3ec5fc;
    max-height: 84px;
    margin: 0 27px;
  }

  .time-text-navigator {
    margin-top: -13px;
    padding: 0 43px 0 57px;
  }

  .travelTime__count {
    margin: 0;
    justify-content: space-around;
  }

  .travelTime__time {
    margin-left: 29px;
  }

  .navigator-map__error-text {
    max-width: 366px;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 10px;
    margin-left: -22px;
  }

  .error-inf {
    height: 91px;
  }

  .travelTime__count-info {
    font-size: 12px;
  }
}
</style>